import React, { useState } from "react";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Contact = ({ resumeData }) => {
  const classes = useStyles();

  const [Contact_Submit_Open, set_Contact_Submit_Open] = useState(false);

  const Contact_submit_handleClickOpen = () => {
    set_Contact_Submit_Open(true);
  };

  const Contact_submit_handleClose = () => {
    window.location.reload();
    set_Contact_Submit_Open(false);
  };

  

  // const performRedirect = () => {

  //   // if (isAuthenticated()) {
  //   //   return <Redirect to="/" />;
  //   // }
  // };


  return (
    <section id="contact">
    
      {/* {successMessage()} */}
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">{resumeData.main?.contactmessage}</p>
        </div>
      </div>

      <div className="row">
        <div className="eight columns">
        <iframe style={{position:'relative',width:'100%',overflow:'hidden'}} src="https://docs.google.com/forms/d/e/1FAIpQLSfWUP9pPGSyFOoZ8SiJDPTH3kgAILUeVShbOZpmMcXjlxwAAQ/viewform?embedded=true" width="640" height="1036" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>          
        </div>

        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4>Address and E-mail</h4>
            <p className="address">
              {resumeData.main?.name}
              <br />
              {resumeData.main?.address.city}, {resumeData.main?.address.state}.{" "}
              {resumeData.main?.address.zip}
              <br />
              <span>{resumeData.main?.email}</span>
            </p>
            {/* <p className="text-center">
                    {JSON.stringify(values)}
                </p> */}
          </div>

          <div className="widget widget_tweets"></div>
        </aside>
      </div>
      <Dialog
        open={Contact_Submit_Open}
        TransitionComponent={Transition}
        keepMounted
        onClose={Contact_submit_handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{ backgroundColor: "#111" }}
          id="alert-dialog-slide-title"
        >
          <h1 style={{ color: "white" }}>{"Thank You!"}</h1>
          <h4 style={{ color: "white" }}>
            {
              "Your message has been successfully sent. We will contact you very soon!"
            }
          </h4>
        </DialogTitle>

        <DialogActions style={{ backgroundColor: "#111" }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Button
              size="large"
              onClick={Contact_submit_handleClose}
              variant="contained"
              color="secondary"
            >
              Ok
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Contact;
