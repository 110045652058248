import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import GitHubIcon from '@material-ui/icons/GitHub';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LaptopIcon from '@material-ui/icons/Laptop';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 500,
    overflow: 'hidden',
    width: '100%',
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Portfolio = ({ resumeData }) => {



  const classes = useStyles();
  const theme = useTheme();


  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const [activeStep, setActiveStep] = useState(0);


  // console.log(portfolio_image)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleOpen = (id) => {
    setSelectedId(id);
    setActiveStep(0);
    //     console.log(resumeData.portfolio?.projects[id]?.image)
    // setportfolio_image(resumeData.portfolio?.projects[id]?.image)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tutorialSteps = [];

  // console.log()
  const maxSteps = tutorialSteps?.length;
  // const maxSteps = 2 


  return (
    <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Check Out Some of My Projects.</h1>

          <div
            id="portfolio-wrapper"
            className="bgrid-quarters s-bgrid-thirds cf"
          >
            {resumeData?.portfolio?.projects.map((project) => {
              var projectImage = project.image[0].image;
              return (
                <div key={project.title} className="columns portfolio-item">
                  <div className="item-wrap">
                    <a
                      href="#portfolio"
                      onClick={() => handleOpen(project.id)}
                      title={project.title}
                    >
                      <img alt={project.title} src={projectImage} />
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>{project.title}</h5>
                          <p>{project.category}</p>
                        </div>
                      </div>
                      <div className="link-icon">
                        <i className="fa fa-link"></i>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {resumeData?.portfolio?.projects.map((project) => {
        if (project.id === selectedId)
          return (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <Grid
                    container

                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >

                    <Grid container justify="center" alignItems="center" item>

                      <Grid item xs={12}>
                        <h2 id="transition-modal-title"> {project.title}</h2>
                      </Grid>
                    </Grid>
                    <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center" item spacing={3}>
                      {
                        project.image.length > 0 &&

                        <div className={classes.root}>
                          <Paper square elevation={0} className={classes.header}>
                            <Typography  ><h4>{project.image[activeStep]?.label}</h4></Typography>
                          </Paper>
                          <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                          >
                            {project.image.map((step, index) => (
                              <div key={step.label}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                  <img className={classes.img} src={step.image} alt={step.label} />
                                ) : null}
                              </div>
                            ))}
                          </AutoPlaySwipeableViews>
                          <MobileStepper
                            steps={project.image.length}
                            position="static"
                            variant="text"
                            activeStep={activeStep}
                            nextButton={
                              <Button size="small" onClick={handleNext} disabled={activeStep === project.image.length - 1}>
                                Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                              </Button>
                            }
                            backButton={
                              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
                            }
                          />
                        </div>
                      }

                    </Grid>
                    <Grid container
                      direction="row-reverse"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={3}>
                        <Button startIcon={<CloseIcon />} color="secondary" variant="contained" onClick={() => handleClose()}> close</Button>
                      </Grid>
                      {project.github !== null &&
                        <Grid item xs={3} >
                          <Button onClick={() => window.location.href = project.github} startIcon={<GitHubIcon />} variant="contained" href="#contained-buttons">Source Code</Button>
                        </Grid>}
                      {project.url !== null &&
                        <Grid item xs={3}>
                          <Button onClick={() => window.location.href = project.url} startIcon={<YouTubeIcon />} variant="contained" color="primary">Video Link</Button>
                        </Grid>}
                      {project.project_live_url !== null &&
                        <Grid item xs={3}>
                          <Button onClick={() => window.location.href = project.project_live_url} startIcon={<LaptopIcon />} variant="contained" href="#contained-buttons">Demo</Button>
                        </Grid>}


                    </Grid>
                  </Grid>
                </div>
              </Fade>
            </Modal>
          );
      })}
    </section>
  );
};

export default Portfolio;
