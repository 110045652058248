import React,{useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { Grid } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const About = ({ resumeData }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
          <img
            className="profile-pic"
            src={resumeData.main?.image}
            alt="Pradyumna Mahajan Profile Pic"
          />
        </div>
        <div className="nine columns main-col">
          <h2>About Me</h2>

          <p>{resumeData.main?.bio}</p>
          <div className="row">
            <div className="columns contact-details">
              <h2>Contact Details</h2>
              <p className="address">
                <span>{resumeData.main?.name}</span>
                <br />
                <span>
                  {resumeData.main?.address.city}{" "}
                  {resumeData.main?.address.state}.{" "}
                  {resumeData.main?.address.zip}
                </span>
                <br />
                <span>{resumeData.main?.email}</span>
              </p>
            </div>
            <div className="columns download">
              <p>
                <a
                  href="#"
                  onClick={() => handleClickOpen()}
                  className="button"
                >
                  <i className="fa fa-download"></i>Download Resume
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{ backgroundColor: "#111" }}
          id="alert-dialog-slide-title"
        >
          <h1 style={{color:"white"}}>{"Upload Soon!!!"}</h1>
        </DialogTitle>

        <DialogActions style={{ backgroundColor: "#111" }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Button
              size="large"
              onClick={handleClose}
              variant="contained"
              color="secondary"
              
            >
              Ok
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default About;
