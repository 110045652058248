export const getResumeData = () => {
  return fetch(`/data/resumeData.json`, {
    method: "GET",
    // headers: {
    //   Authorization: "Token " + access_token,
    // },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};


