import React, { useState, useEffect } from "react";
import Portfolio from "./core/Portfolio";
import About from "./core/About";
import Contact from "./core/Contact";
import Footer from "./core/Footer";
import Header from "./core/Header";
import { getResumeData } from "./core/helper/coreapicalls";
import Resume from "./core/Resume";

const App = () => {
  const [resumeData, setresumeData] = useState([]);
  const [error, setError] = useState(false);

  const loadAllresume = () => {
    getResumeData().then((data) => {
      if (data.error) {
        setError(data.error);
        console.log(error);
      } else {
        setresumeData(data);
      }
    });
  };

  useEffect(() => {
    loadAllresume();
    //    loadAllPosts();
    setInterval(() => {
      loadAllresume();
    }, 500);
  }, []);
  // console.log(resumeData);

  return (
    <div className="App">
      <Header resumeData={resumeData}></Header>
      <About resumeData={resumeData}></About>
      <Resume resumeData={resumeData}></Resume>
      <Portfolio resumeData={resumeData}></Portfolio>
      <Contact resumeData={resumeData}></Contact>
      <Footer resumeData={resumeData}></Footer>
    </div>
  );
};

export default App;

// Photo by Neale LaSalle from Pexels
