import React from "react";

const Footer = ({ resumeData }) => {
  return (
    <footer>
      <div className="row">
        <div className="twelve columns">
          <ul className="social-links">
            {resumeData.main?.social.map((network) => {
              return (
                <li key={network.name}>
                  <a href={network.url}>
                    <i className={network.className}></i>
                  </a>
                </li>
              );
            })}
          </ul>

          <ul>
            <li>Modified by Pradyumna Mahajan</li>
          </ul>

          <ul className="copyright">
            <li>Copyright&copy;2021 psmweb.in</li>
            <li>
              &copy; All credit goes to{" "}
              <a
                title="Time Baker"
                href="https://github.com/tbakerx/react-resume-template"
              >
                Tim Baker
              </a>
              &amp;{" "}
              <a
                title="Time Baker"
                href="https://github.com/CleverProgrammers/react-portfolio"
              >
                CleverProgrammers
              </a>
            </li>
            <li>
              Design by{" "}
              <a title="Styleshout" href="http://www.styleshout.com/">
                Styleshout
              </a>
            </li>
          </ul>
        </div>
        <div id="go-top">
          <a className="smoothscroll" title="Back to Top" href="#home">
            <i className="icon-up-open"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
