import React from "react";

const Resume = ({ resumeData }) => {
  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>
        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">
              {" "}
              {resumeData.resume?.education.map((re) => {
                return (
                  <div key={re.school}>
                    <h3>{re.school}</h3>
                    <p className="info">
                      {re.degree} <span>&bull;</span>
                      <em className="date">{re.graduated}</em>
                    </p>
                    <p>{re.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>Work</span>
          </h1>
        </div>

        <div className="nine columns main-col">{work}</div>
      </div> */}

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="bars">
            <ul className="skills">
              {resumeData.resume?.skills.map((skill) => {
                var className = "bar-expand " + skill.name.toLowerCase();
                return (
                  <li key={skill.name}>
                    <span
                      style={{ width: skill.level }}
                      className={className}
                    ></span>
                    <em>{skill.name}</em>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
